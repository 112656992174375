import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Button, CardHeader,
  Table
} from 'reactstrap';
import Menu from '../../components/Menu';
import './Log.scss';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ReactComponent as IconActivity } from '../../images/icn_attività.svg';

function Log({ match }) {
  const [t] = useTranslation('CONTACTS');

  return (
    <div className="App">
      <Menu match={match} />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconActivity fill='#cf312c' />}
          />

          <div className="row m-b-30">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <Button className="btn-plus">
                Esporta attività
              </Button>
            </div>
          </div>

          <Card className="card-activities">
            <CardHeader>
              Giovedì 26 Giugno 2019
            </CardHeader>
            <CardBody>
              <Table responsive hover className="data-table activity-table">
                <tbody>
                  <tr>
                    <td>Ore: <b>14:50</b></td>
                    <td>Nome cognome</td>
                    <td className="close-date"><b>AZIONE:</b> Data chiusura</td>
                  </tr>
                  <tr>
                    <td>Ore: <b>14:50</b></td>
                    <td>Nome cognome</td>
                    <td className="close-date"><b>AZIONE:</b> Data chiusura</td>
                  </tr>
                  <tr>
                    <td>Ore: <b>14:50</b></td>
                    <td>Nome cognome</td>
                    <td className="close-date"><b>AZIONE:</b> Data chiusura</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>

        </section>
        <Footer />
      </div>
    </div >
  );
}

export default Log;
