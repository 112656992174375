export const COST_CENTERS = [
  {
    name: 'translations',
    color: '#6689A1'
  },
  {
    name: 'training',
    color: '#D8DC6A'
  },
  {
    name: 'interpreting',
    color: '#EB8258'
  },
  {
    name: 'copywriting',
    color: '#995D81'
  }
]