import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import { useAuth } from '../../components/Auth';
import './Info.scss';
import { Form, FormGroup, Label } from 'reactstrap';
import { getMailtoAnchor } from '../../lib/helpers/mailto';
const EMAIL = process.env.REACT_APP_EMAIL_SUPPORT;

function Info() {
  const [t] = useTranslation();
  const [{ profile = {} } = {}] = useAuth();
  const { first_name, last_name, email, role } = profile;

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('COMMON:PROFILE')}
            icon={<IconProfile fill='#cf312c' />}
          />
          <Card>
            <CardBody>
              <Form className="m-b-35 profile-form">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <FormGroup>
                      <Label>{t('USERS:FIRST_NAME')}</Label>
                      <p><strong>{first_name}</strong></p>
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <FormGroup>
                      <Label>{t('USERS:LAST_NAME')}</Label>
                      <p><strong>{last_name}</strong></p>
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <FormGroup>
                      <Label>{t('USERS:EMAIL')}</Label>
                      <p><strong>{email}</strong></p>
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <FormGroup>
                      <Label>{t('USERS:ROLE')}</Label>
                      <p><strong>{role}</strong></p>
                    </FormGroup>
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
        </section>

        <p className="text-center email-support">
          <small>
            {t('COMMON:SUPPORT')}: {getMailtoAnchor(EMAIL, <b>{EMAIL}</b>)}
          </small>
        </p>
        <Footer />
      </div>

      <BurgerMenu />
    </div>
  );
}

export default Info;
