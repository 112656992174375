import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import { ReactComponent as IconProfile } from '../../images/icn_attività.svg';
import { DataTable, Field, DateTimeField } from '../../components/DataTable';
import { ActivityTypeField } from '../../components/DataTableFields';
import ActivityModal from '../../components/ActivityButtonGroup/ActivityModal';

const Contact = ({ data }) => {
  const { contact_last_name = '', contact_first_name = '' } = data || {}
  return (
    <div>{contact_first_name} {contact_last_name}</div>
  )
}

function ActiivitiesPresentational({ activities, total, query, onFilter, isLoading, onSaveActivity, onDeleteActivity }) {
  const [t] = useTranslation('ACTIVITIES');
  const { offset } = query
  const modalRef = useRef();
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState();

  const toggle = () => setOpen(!open);

  const openModal = (activity = {}) => {
    setActivity(activity)
    toggle()
    setTimeout(() => modalRef.current.resetForm(), 200)
  }

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('COMMON:ACTIVITIES')}
            icon={<IconProfile fill='#cf312c' />}
          />

          <Card>
            <CardBody>
              <DataTable
                offset={offset}
                total={total}
                data={activities}
                isLoading={isLoading}
                loadingText={t('COMMON:LOADING')}
                emptyText={t('COMMON:NO_DATA')}
                onFilter={onFilter}
                onRowClick={openModal}
              >
                <Field title={t('COMMON:DESCRIPTION')} source="description" maxLength={40} />
                <ActivityTypeField title={t('TYPE')} source="type" />
                <Contact title={t('CONTACT')} />
                <Field title={t('DEAL')} source="deal_name" />
                <DateTimeField title={t('DATE')} source="date" />
                <Field title={t('DONE')} source='done' />
              </DataTable>
            </CardBody>
          </Card>

          <ActivityModal
            ref={modalRef}
            activity={activity}
            open={open}
            toggle={toggle}
            onSubmit={(values) => {
              onSaveActivity({ values, type: activity.type, id: activity.id })
              toggle()
            }}
            onDeleteActivity={() => {
              onDeleteActivity(activity.id)
              toggle()
            }}
            type={activity && activity.type}
          />

        </section>
        <Footer />
      </div>
      <BurgerMenu />
    </div>
  );
}

export default ActiivitiesPresentational;
