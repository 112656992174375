import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Nav, NavItem, Button, Card, CardBody } from 'reactstrap';
import Menu from '../../components/Menu';
import './CompanyDetail.scss';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ReactComponent as IconCompanies } from '../../images/icn_aziende.svg';
import backIcon from '../../images/icn-back.svg';
import trashIcon from '../../images/icn-trash.svg';
import { Link, Route, Switch, Redirect } from "react-router-dom";
import CompanyInfo from './CompanyInfo';
import CompanyContacts from './CompanyContacts';
import ModalConfirm from '../../components/ModalConfirm';
import DealsTable from '../Deals/DealsTable';

function CompanyDetail({ match, history, location, company, onSave, onDelete, contacts, deals, total, isLoading, onFilter, query }) {
  const [t] = useTranslation('COMPANYDETAIL');
  const { pathname = '' } = location
  const { url = '' } = match
  const tab = pathname.split('/').pop()
  if (pathname === url) {
    return <Redirect to={`${match.url}/info`} />
  }

  const { name = '' } = company

  return (
    <div className="App">
      <Menu match={match} />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconCompanies fill='#cf312c' />}
          />

          <div className="title-header">
            <div className="row m-b-30">
              <div className="col-sm-12 col-md-10">
                <h4>
                  <Button className="btn-plus" onClick={() => history.push('/companies')}>
                    <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
                  </Button>
                  <b>{name}</b>
                </h4>
              </div>
              <div className="edit-element">
                <ModalConfirm text={t('DELETE_REQUEST')} onConfirm={onDelete}>
                  <Button className="btn-trash">
                    <img src={trashIcon} alt="" />
                  </Button>
                </ModalConfirm>
              </div>
            </div>
          </div>

          <Nav tabs style={{marginTop: '-30px'}}>
            <NavItem>
              <Link to={`${match.url}/info`} className={classnames({ active: tab === 'info', 'nav-link': true })}>
                {t('COMPANY_DATA')}
              </Link>
            </NavItem>
            <NavItem>
              <Link to={`${match.url}/contacts`} className={classnames({ active: tab === 'contacts', 'nav-link': true })}>
                {t('COMMON:CONTACTS')}
              </Link>
            </NavItem>
            <NavItem>
              <Link to={`${match.url}/deals`} className={classnames({ active: tab === 'deals', 'nav-link': true })}>
                {t('COMMON:DEALS')}
              </Link>
            </NavItem>
          </Nav>

          <Switch>
            <Route
              path={`${match.url}/info`}
              component={props => <CompanyInfo company={company} onSave={onSave} {...props} />}
            />
            <Route
              path={`${match.url}/contacts`}
              component={props => <CompanyContacts contacts={contacts} {...props} />}
            />
            <Route
              path={`${match.url}/deals`}
              component={props => (
                <Card>
                  <CardBody>
                    <DealsTable
                      deals={deals}
                      total={total}
                      query={query}
                      isLoading={isLoading}
                      onFilter={onFilter}
                      {...props}
                    />
                  </CardBody>
                </Card>
              )} />
          </Switch>

        </section>
        <Footer />
      </div>
    </div >
  );
}
export default CompanyDetail;
