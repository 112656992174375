import React from 'react';
import { Helmet } from "react-helmet";

const HeadMeta = ({ children }) => {
  const { NODE_ENV } = process.env
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRM Landoor {NODE_ENV !== 'production' ? `- ${NODE_ENV.toUpperCase()}` : ''}</title>
      </Helmet>
      {children}
    </>
  );
};


export default HeadMeta;
