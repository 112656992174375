import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  UncontrolledCollapse, Button, Card, CardBody,
} from 'reactstrap';
import Menu from '../../components/Menu';
import './Dashboard.scss';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BalanceCont from '../../components/BalanceCont';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import { DataTable, Field, CurrencyField, DateTimeField } from './../../components/DataTable';
import { StageField } from './../../components/DataTableFields';
import CostCenterChart from '../../components/CostCenterChart';
import BudgetOfferReport from '../../components/BudgetOfferReport';
import DashboardFilters from '../../components/DashboardFilters';

function DashboardPresentational({ match, history, dashboardData = {}, isLoading, query, onFilter }) {
  const [t] = useTranslation('DASHBOARD');

  const { balance: {
    addedContacts = 0,
    closedContracts = 0,
    floatingContracts = 0,
    leads = 0,
    lostContracts = 0
  } = {},
    deals = [],
    costCentres = [],
    dealsBudgetsRatio = []
  } = dashboardData
  return (
    <div className="App">
      <Menu match={match} />

      <div className="corpo">
        <section>
          <Header
            title={t('COMMON:DASHBOARD')}
            icon={<IconDashboard fill='#cf312c' />}
          />
          
          <Button className="btn-collapse" id="toggler" style={{ marginBottom: '1rem' }}>
            Fltra per <i className="fas fa-filter"></i>
          </Button>
          <UncontrolledCollapse toggler="#toggler">
            <div className="m-t-20">
              <DashboardFilters
                query={query}
                onFilter={onFilter}
              />
            </div>
          </UncontrolledCollapse>

          <hr />

          <h4>{t('FINANCIAL_STATEMENT')}</h4>

          <div className="d-flex">
            <BalanceCont title={t("CLOSED_LEADS")} value={leads} unit={t("LEADS")} />
            <BalanceCont title={t("ADDED_CONTACTS")} value={addedContacts} unit={t("CONTACTS")} />
            <BalanceCont title={t("CLOSED_CONTRACTS")} value={closedContracts} unit={t("CONTRACTS")} />
            <BalanceCont title={t("LOST_CONTRACTS")} value={lostContracts} unit={t("CONTRACTS")} />
            <BalanceCont title={t("FLOATING_CONTRACTS")} value={floatingContracts} unit={t("CONTRACTS")} />
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h4>{t('REPORT_TITLE')}</h4>
              <Card>
                <CardBody>
                  <BudgetOfferReport dealsBudgetsRatio={dealsBudgetsRatio} />
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-12 col-md-6">
              <h4>{t('COST_CENTER_TITLE')}</h4>
              <Card>
                <CardBody>
                  <CostCenterChart costCentres={costCentres} />
                </CardBody>
              </Card>
            </div>
          </div>

          <h4>{t('DEALS_TITLE')}</h4>

          <Card>
            <CardBody>
              <DataTable
                data={deals}
                isLoading={isLoading}
                loadingText={t('COMMON:LOADING')}
                emptyText={t('COMMON:NO_DATA')}
                onFilter={onFilter}
                onRowClick={({ id } = {}) => history.push(`/deal-detail/${id}`)}
              >
                <CurrencyField title={t('COMMON:AMOUNT')} source="amount" className="u-text-right" />
                <Field title={t('COMMON:OFFER_NAME')} source="name" />
                <Field title={t('COMMON:COMPANY')} source="company_name" />
                <Field title={t('COMMON:CONTACT')} source="contact_name" />
                <DateTimeField title={t('COMMON:CLOSING_DATE')} format="DD/MM/YYYY" source="closing_date" className="close-date" />
                <StageField title={t('COMMON:OFFER_STATUS')} source="stage" />
              </DataTable>
            </CardBody>
          </Card>

        </section>
        <Footer />
      </div>


      <BurgerMenu />
    </div>
  );
}

export default withRouter(DashboardPresentational);
