import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import SearchAddCont from '../../components/SearchAddCont';
import { RubricColumn, RubricTable } from '../../components/RubricTable';
import UserModal from './UsersModal';
import { useAlert } from 'react-alert';
import UserRoleLabel from './UserRoleLabel';
import './Users.scss'

function UsersPresentational(props) {
  const {
    onFilter,
    query,
    isLoading = false,
    isAdding = false,
    users,
    onSaveUser,
    onCreateUser,
    onDeleteUser,
  } = props;

  const modalRef = useRef();

  const [t] = useTranslation('USERS');
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState();
  const toggle = () => setOpen(!open);
  const alert = useAlert();

  const openModal = (user = {}) => {
    if (user && user.role === 'admin') {
      alert.info(t('MESSAGE_MODIFY_ADMIN'))
      return
    };

    setUser(user)
    toggle()
    setTimeout(() => modalRef.current.resetForm(), 200)
  }

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconProfile fill='#cf312c' />}
          />

          <SearchAddCont
            isLoading={isAdding}
            onAdd={openModal}
            addLabel={t('BTN_ADD_USER')}
            onFilter={onFilter}
            query={query}
          />

          <hr />

          <Card>
            <CardBody>
              <RubricTable
                onItemClick={openModal}
                isLoading={isLoading}
                items={users}
                keyword="last_name"
              >
                <RubricColumn source="last_name+first_name" />
                <RubricColumn source="email" />
                <RubricColumn source="role">
                  <UserRoleLabel />
                </RubricColumn>
                <RubricColumn className="text-right">
                  <i className="fas fa-chevron-right" />
                </RubricColumn>
              </RubricTable>
            </CardBody>
          </Card>
        </section>

        <UserModal
          ref={modalRef}
          open={open}
          toggle={toggle}
          user={user}
          onSubmit={values => {
            const { id } = user || {}
            id ? onSaveUser(id, values).then(toggle) : onCreateUser(values).then(toggle)
          }}
          onDeleteUser={id => onDeleteUser(id).then(toggle)}
        />

        <Footer />
      </div>
      <BurgerMenu />
    </div>
  );
}

export default UsersPresentational;


