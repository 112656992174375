import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import { ReactComponent as IconDeals } from '../../images/icn_offerte.svg'
import Filters from '../../components/Filters'
import { UncontrolledCollapse, Button, Card, CardBody } from 'reactstrap'
import plusIcon from '../../images/icn-plus.svg'
import Footer from '../../components/Footer'
import BurgerMenu from '../../components/BurgerMenu'
import DealsTable from './DealsTable';
import Spinner from '../../components/Spinner';

function DealsPresentational(props) {
  const {
    deals,
    total = 0,
    query = {},
    onFilter,
    onCreateDeal,
    isLoading = false,
    isAdding = false,
  } = props;

  const [t] = useTranslation('DEALS');

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconDeals fill='#cf312c' />}
          />

          <div className="row">
            <div className="col-6">
              <Button className="btn-collapse" id="toggler" style={{ marginBottom: '1rem' }}>
                Fltra per <i className="fas fa-filter"></i>
              </Button>
            </div>
            <div className="col-6 text-right">
              {
                isAdding ?
                  <div>
                    <Spinner />
                  </div>
                  :
                  <Button className="btn-plus" onClick={() => onCreateDeal()}>
                    <img src={plusIcon} alt="" /> {t('ADD_OFFER')}
                  </Button>
              }
            </div>
          </div>

          <UncontrolledCollapse toggler="#toggler">
            <div className="m-t-20">
              <Filters
                query={query}
                onFilter={onFilter}
              />
            </div>
          </UncontrolledCollapse>

          <hr />

          <Card>
            <CardBody>
              <DealsTable
                deals={deals}
                total={total}
                query={query}
                onFilter={onFilter}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>

        </section>
        <Footer />
      </div>

      <BurgerMenu />
    </div >
  )
}

export default DealsPresentational
